import { httpRequest } from "./http";

export const newsListApi = params => httpRequest("/news/", 'get', params);
export const newsDetailApi = params => httpRequest(`/news/${params.id}`, 'get');
export const shopPage = params => httpRequest("/stores/page", 'get', params);
export const membershipGradePage = params => httpRequest("/membershipGrade/page", 'get', params);
export const recruitList = (params) => httpRequest("/recruit/list", 'get', params);
export const recruitDetail = (id) => httpRequest(`/recruit/${id}`, 'get');


// export const newsDetailApi = id => httpRequest(`/news/${id}`, 'get');
export const datadicAll = params => httpRequest(`/datadic/all`, 'get', params);
export const commentList = params => httpRequest(`/comment`, 'get', params);
export const advise = params => httpRequest(`/advise`, 'post', params);
export const trial = params => httpRequest(`/trial`, 'post', params);

export const productList = params => httpRequest(`/product/list`, 'get', params);
export const productDetail = (id) => httpRequest(`/product/${id}`, 'get');