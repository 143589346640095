// 引入 axios
import axios from "axios";
// import router from "@/router";
import { Message } from 'element-ui'
import { handelGetUrl } from '@/libs/util.ts'

//正式环境用户希望能自己配置
axios.defaults.baseURL = process.env.VUE_APP_baseURL

// 超时时间
axios.defaults.timeout = 600000;
axios.defaults.withCredentials = false;
axios.defaults.headers["Content-Type"] = "application/json";

axios.interceptors.request.use(
    config => {
        return config;
    },
    err => Promise.reject(err)
);

// 响应拦截
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },

    // 服务器状态码不是200的情况
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    // router.replace({
                    //     path: "/login",
                    //     query: {
                    //         redirect: router.currentRoute.fullPath
                    //     }
                    // });
                    break;
                case 404:
                    Message.error('网络请求不存在');
                    break;
                default:
                    Message.error(error.response.data.message);
            }
            return Promise.reject(error.response);
        }
    }
);

export function httpRequest(url, type, params, specialSucc) {
    return new Promise((resolve, reject) => {
        
        // 处理type为get时，将参数放到url后面
        // if(type == 'get') url = handelGetUrl(url, type, params)

        axios[type](url, type == 'get'?{params: params}:params)
            .then(res => {
                if (specialSucc) {
                    //特殊接口返回处理
                    resolve(res);
                    return;
                }

                if (res.data.code == 200) {
                    resolve(res.data.data);
                } else {
                    Message.destroy()
                    Message.error(res.data.msg || "网络异常");
                    reject(res)
                }
            })
            .catch(err => {
                // Message.error(err);
            });
    });
}