import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import EventBus from 'vue-bus-ts';
import '@/css/main.scss'
import '@/css/index.scss'
import '@/css/animate.min.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
const MetaInfo = require('vue-meta-info' )
Vue.use(ElementUI);
Vue.use(EventBus);
Vue.use(MetaInfo) 

//使用$bus组件传值
Vue.prototype.$bus = new Vue()
Vue.config.productionTip = false

var bus = new EventBus.Bus();

new Vue({
  router,
  store,
  bus,
  render: h => h(App),
  mounted() { 
    document.dispatchEvent(new Event('render-event')) 
  } 
}).$mount('#app')
