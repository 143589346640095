import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        redirect: {
            name: 'home'
        }
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            menuIndex: 0,
            keepAlive: true
        },
        component: () => import('@/views/home/home.vue')
    },
    {
        path: '/product',
        name: 'product',
        meta: {
            menuIndex: 1,
            keepAlive: true
        },
        component: () => import('@/views/product/product.vue')
    },
    {
        path: '/productDetail',
        name: 'productDetail',
        meta: {
            menuIndex: 1,
            // fixedHead: true
        },
        component: () => import('@/views/product/productDetail.vue')
    },
    {
        path: '/brand',
        name: 'brand',
        meta: {
            menuIndex: 2,
            keepAlive: true
        },
        component: () => import('@/views/brand/index.vue')
    },
    {
        path: '/newsList',
        name: 'newsList',
        meta: {
            menuIndex: 3,
            keepAlive: true
        },
        component: () => import('@/views/news/newsList.vue')
    },
    {
        path: '/newsDetail',
        name: 'newsDetail',
        meta: {
            menuIndex: 3,
            // fixedHead: true
        },
        component: () => import('@/views/news/newsDetail.vue')
    },
    {
        path: '/members',
        name: 'members',
        meta: {
            menuIndex: 4,
            keepAlive: true
        },
        component: () => import('@/views/members.vue')
    },
    // {
    //     path: '/shop',
    //     name: 'shop',
    //     meta: {
    //         menuIndex: 5,
    //     },
    //     component: () => import('@/views/shop.vue')
    // },
    {
        path: '/job',
        name: 'job',
        meta: {
            menuIndex: 5,
            keepAlive: true
        },
        component: () => import('@/views/job/jobList.vue')
    },
    {
        path: '/jobDetail',
        name: 'jobDetail',
        meta: {
            menuIndex: 5,
            // fixedHead: true
        },
        component: () => import('@/views/job/jobDetail.vue')
    },
    {
        path: '/joinUs',
        name: 'joinUs',
        meta: {
            menuIndex: 6,
            keepAlive: true
            // fixedHead: true
        },
        component: () => import('@/views/joinUs.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router
