

































import { Vue, Component } from "vue-property-decorator";
@Component
export default class webFoot extends Vue {
    public year = 2022;

    public mounted() {
        this.year = new Date().getFullYear();
    }
}
