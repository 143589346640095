






import { Vue, Component } from "vue-property-decorator";
import layout from "@/views/layout.vue";
@Component({
    components: {
        layout,
    },
})
export default class App extends Vue {
    public mounted() {
        // 移动端设备跳转h5
        if (
            navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
        ) {
           window.location.href = "https://www.simplesleep.cn/h5/"
        }
    }
}
