


























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { advise } from '@/api'
@Component

export default class adviceForm extends Vue {
    @Prop({
        type: Boolean,
        required: true,
        default: false
    }) private value!: Boolean

    @Watch('value') private(val: Boolean) {
        this.dialogVisible = val
    }

    public formData: any = {
        content: '',
        userName: '',
        email: '',
        phone: '',
    }

    public dialogVisible = this.value
    public handleClose() {
        this.$bus.$emit('adviceVisible', false)
    }
    public async handelSubmit() {
        if (!this.formData.content || !this.formData.userName || !this.formData.email || !this.formData.phone) {
            this.$message.error('请输入必填项')
            return
        }
        this.$message.success('提交成功')
        this.formData = {
            content: '',
            userName: '',
            email: '',
            phone: '',
        }
        this.handleClose()
        return
        await advise(this.formData).then(res => {
            this.$message.success('提交成功')
            this.formData = {
                content: '',
                userName: '',
                email: '',
                phone: '',
            }
            this.handleClose()
        })

    }
}
