















import { Vue, Component } from "vue-property-decorator";
import webHead from "@/components/webHead.vue";
import webFoot from "@/components/webFoot.vue";
import toolBar from "@/components/toolBar.vue";
import adviceForm from '@/components/adviceForm.vue'
@Component({
    components: {
        webHead,
        webFoot,
        toolBar,
        adviceForm
    }
})
export default class layout extends Vue {
    public adviceVisible: boolean = false

    public async mounted() {
        this.$bus.$on('adviceVisible', (val: any) => {
            this.adviceVisible = val
        })
    }
}
