

















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { debounce } from "@/libs/util.ts";
@Component
export default class webHead extends Vue {
    @Watch("$route") routechange(to: any) {
        if (to.meta && to.meta.fixedHead) this.isScroll = true;
        this.getMenuIndex(to);
    }

    private menuIndex = 0;
    private isScroll = false;
    private tabArr = [
        {
            name: "首页",
            route: "/",
            menuIndex: 0,
        },
        {
            name: "产品指南",
            route: "/product",
            menuIndex: 1,
        },
        {
            name: "品牌介绍",
            route: "/brand",
            menuIndex: 2,
        },
        {
            name: "新闻动态",
            route: "/newsList",
            menuIndex: 3,
        },
        {
            name: "会员服务",
            route: "/members",
            menuIndex: 4,
        },
        // {
        //     name: '线下门店',
        //     route: '/shop',
        //     menuIndex: 5
        // },
        {
            name: "招聘岗位",
            route: "/job",
            menuIndex: 5,
        },
        {
            name: "联系我们",
            route: "/joinUs",
            menuIndex: 6,
        },
    ];

    private mounted() {
        //监听页面滚动，将顶部菜单背景置换
        window.addEventListener("scroll", this.handelScroll, true);
    }

    private linkTo(url: any) {
        this.$router.push(url);
    }

    private getMenuIndex(to: any) {
        const objMenu = this.tabArr.filter((item) => item.route == to.name);
        if (objMenu.length) {
            this.menuIndex = objMenu[0].menuIndex;
        } else {
            this.menuIndex = to.meta.menuIndex;
        }
    }

    private handelScroll() {
        debounce(() => {
            let scroll = document.body.scrollTop || document.documentElement.scrollTop || window.pageXOffset;
            let scrollHeight = 50;
            if (scroll > scrollHeight || (this.$route.meta && this.$route.meta.fixedHead)) {
                this.isScroll = true;
            } else {
                this.isScroll = false;
            }

            //新闻列表 固定新闻分类
            // if (scroll > 270) {
            //     (this as any).$bus.$emit('handelShowNewsBar', true)
            // } else {
            //     (this as any).$bus.$emit('handelShowNewsBar', false)
            // }

            // //右侧工具栏
            // if (scroll > 900) {
            //     (this as any).$bus.$emit('handelShowToolBar', true)
            // } else {
            //     (this as any).$bus.$emit('handelShowToolBar', false)
            // }
        }, 10);
    }
}
