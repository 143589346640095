//防抖
let debounceTimer: any = null
export const debounce = (fn: any, delay = 200) => {
    if (debounceTimer) {
        clearTimeout(debounceTimer)
    }
    debounceTimer = setTimeout(fn, delay)
}

export const handelGetUrl = (url: string, type: string, params: any) => {
    if(params) {
        if(url.includes('?')) url += '&' 
        else url += '?'
        for(let key in params) {
            url += `${key}=${params[key]}&`
        }
        url = url.substring(0, url.length - 1)
    }
    return url
}