















import { Vue, Component } from "vue-property-decorator";
@Component
export default class toolBar extends Vue {
    public isShow = false;
    public mounted() {
        (this as any).$bus.$on("handelShowToolBar", (Boolean: boolean) => {
            this.isShow = Boolean;
        });
    }

    public openQQ() {
        window.open("http://wpa.qq.com/msgrd?v=3&uin=3736172&site=qq&menu=yes", "_brank");
    }

    private emitBtn() {
        this.$bus.$emit("adviceVisible", true);
    }

    public scrollTop() {
        // document.body.scrollTop=document.documentElement.scrollTop=0
        window.scrollTo({
            left: 0,
            top: 0,
            behavior: "smooth",
        });
    }
}
